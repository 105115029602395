import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Box,
  Label,
  Text,
  Input,
  Textarea,
  Button,
  Heading,
  Alert,
  Close,
} from "theme-ui"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import SEO from "../components/seo"
import { Spinner } from "theme-ui"

const axios = require("axios")

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "contactUsMobileBanner.png" }) {
      ...fluidImage
    }
    desktopBanner: file(relativePath: { eq: "contactUsDesktopBanner.png" }) {
      ...fluidImage
    }
  }
`

export default function ContactUs({ data }) {
  const [formValues, setformValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [success, setSuccess] = useState(null)
  const handleChange = e => {
    setformValues({ ...formValues, [e.target.name]: e.target.value })
  }
  const handleReset = () =>
    setformValues({
      name: "",
      email: "",
      phone: "",
      message: "",
    })
  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitting(true)
    // axios
    //   .post("dummy", formValues)
    //   .then(response => {
    //     setIsSubmitting(false)
    //     setSuccess(true)
    //     setShowToast(true)
    //     handleReset()
    //   })
    //   .catch(err => {
    //     setIsSubmitting(false)
    //     setSuccess(false)
    //     setShowToast(true)
    //   })
    setTimeout(() => {
      setIsSubmitting(false)
      setSuccess(true)
      setShowToast(true)
    }, 2000)
  }
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Contact Us"
        body={
          <Box>
            <Text variant="default" color="#707070">
              We’re very approachable! Feel free to call, send us an email,
              tweet us or simply fill in the enquiry form. We’d love to hear
              from you.
            </Text>
            <Heading mt={2}>Email</Heading>
            <Text color="muted">info@yourgreencanvas.com</Text>
            <Heading mt={2}>Phone</Heading>
            <Text sx={{ color: "muted" }}>99001-70470 / 98457-62765</Text>
          </Box>
        }
        alt="contactUs"
      />
      <Box
        as="form"
        p={4}
        onSubmit={handleSubmit}
        sx={{ width: "100%", maxWidth: "700px", margin: "auto" }}
      >
        <Label htmlFor="name">Name</Label>
        <Input
          name="name"
          id="name"
          mb={3}
          value={formValues.name}
          onChange={handleChange}
          required
        />
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          mb={3}
          value={formValues.email}
          onChange={handleChange}
          required
        />
        <Label htmlFor="phone">Phone Number</Label>
        <Input
          type="tel"
          name="phone"
          id="phone"
          mb={3}
          value={formValues.phone}
          onChange={handleChange}
          required
        />
        <Label htmlFor="message">Message</Label>
        <Textarea
          name="message"
          id="message"
          rows={6}
          mb={3}
          value={formValues.message}
          onChange={handleChange}
        />
        <Box sx={{ textAlign: "right" }}>
          <Button
            mr={2}
            px={4}
            bg="transparent"
            color="accent2"
            type="reset"
            sx={{
              borderColor: "footerColor",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "5px",
            }}
            onClick={handleReset}
          >
            Clear
          </Button>
          <Button
            type="submit"
            px={4}
            bg="footerColor"
            sx={{ borderRadius: "5px" }}
          >
            {isSubmitting ? <Spinner size={20} mx={4} /> : "Submit"}
          </Button>
        </Box>
        {showToast ? (
          <Alert my={2} sx={{ fontSize: 3 }}>
            {success
              ? " We appreciate you contacting us. We will get back in touch with you soon!"
              : "Form submission failed try again sometime later!"}
            <Close
              onClick={() => setShowToast(false)}
              type="button"
              ml="auto"
              mr={-2}
            />
          </Alert>
        ) : (
          <></>
        )}
      </Box>
    </Layout>
  )
}
